import { Component, OnInit } from '@angular/core';
import { ActivityService } from "../../service/activity.service";
import { Activity } from "../../model/activity.model";
import { PageEvent } from "@angular/material/paginator";
import { getEmptyPage, Page } from "../../model/page.model";
import { Filter } from "../../model/filter.model";
import { FormControl, FormGroup } from "@angular/forms";
import { ActivityType } from "../../model/activity-type.model";
import { DeviceType } from "../../model/device-type.model";

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss']
})
export class ActivityListComponent implements OnInit {

  displayedColumns = ['browserOrDeviceId', 'sessionId', 'userId', 'activityType', 'contentId', 'contentTerm',
    'remarks', 'deviceType', 'createdAt'];
  activityPage: Page<Activity> = getEmptyPage();
  filter: Filter = {siteId: 1, pageSize: 5};
  form = new FormGroup({
    browserOrDeviceId: new FormControl(),
    sessionId: new FormControl(),
    userId: new FormControl(),
    activityType: new FormControl(),
    contentId: new FormControl(),
    contentTerm: new FormControl(),
    deviceType: new FormControl(),
  });
  activityTypes = Object.values(ActivityType).filter(value => typeof value === 'string');
  deviceTypes = Object.values(DeviceType).filter(value => typeof value === 'string');

  constructor(private activityService: ActivityService) { }

  ngOnInit(): void {
    this.getByFilter()
  }

  getByFilter() {
    this.filter.browserOrDeviceId = this.form.controls.browserOrDeviceId.value;
    this.filter.sessionId = this.form.controls.sessionId.value;
    this.filter.userId = this.form.controls.userId.value;
    this.filter.activityType = this.form.controls.activityType.value;
    this.filter.contentId = this.form.controls.contentId.value;
    this.filter.contentTerm = this.form.controls.contentTerm.value;
    this.filter.deviceType = this.form.controls.deviceType.value;
    this.activityService.getByFilter(this.filter).subscribe(res => this.activityPage = res);
  }

  handlePageEvent(event: PageEvent) {
    this.filter.pageNumber = event.pageIndex;
    this.filter.pageSize = event.pageSize;
    this.getByFilter();
  }
}
