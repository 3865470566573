import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ActivityListComponent } from "./component/activity-list/activity-list.component";
import { LoginComponent } from "./component/authentication/login/login.component";
import { AuthGuard } from "./middleware/auth.guard";

const routes: Routes = [
  {path: 'login', component: LoginComponent},
  {path: '', component: ActivityListComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}
