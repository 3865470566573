export enum ActivityType {
  LOGIN,
  SIGN_UP,
  HOME_PAGE_VISIT,
  DETAILS_PAGE_VISIT,
  AUTHOR_PAGE_VISIT,
  PUBLISHER_PAGE_VISIT,
  BROWSE_PAGE_VISIT,
  BRAND_PAGE_VISIT,
  TOP_SEARCH,
  ORDER_PLACE,
  WISH_LIST_ADD,
  LIST_ADD,
  CART_PAGE_VISIT,
  SHIPPING_PAYMENT_PAGE_VISIT,
  LOOK_INSIDER_CLICK
}
