<div class="wrapper">
  <header>
    <mat-toolbar color="primary">
      <span>Activity Manager Dashboard</span>
      <span class="toolbar-spacer"></span>
      <div *ngIf="isLoggedIn()" class="button-div">
        <button mat-button type="button" color="accent" (click)="logout()">Logout</button>
      </div>
    </mat-toolbar>
  </header>
  <router-outlet></router-outlet>
  <div class="push"></div>
</div>
<footer>
  <mat-toolbar color="primary">
    <span>&copy; Rokomari.com</span>
  </mat-toolbar>
</footer>
