import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, switchMap, throwError } from 'rxjs';
import { AuthService } from "../service/auth.service";
import { Router } from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private isRefreshing = false;

  constructor(private authService: AuthService, private router: Router) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (this.authService.isLoggedIn()) {
      const authorizedRequest = this.getAuthorizedRequest(request);
      return next.handle(authorizedRequest)
        .pipe(catchError(err => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            return this.handle401Error(authorizedRequest, next);
          }

          return throwError(() => err);
        }))
    } else if (!request.url.match("login")) {
      this.router.navigate(['/login'])
    }

    return next.handle(request);
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;

      if (this.authService.isLoggedIn()) {
        return this.authService.refreshToken().pipe(
          switchMap(() => {
            this.isRefreshing = false;
            return next.handle(this.getAuthorizedRequest(request));
          }),
          catchError((error) => {
            this.isRefreshing = false;

            if (error.status == '422') {
              this.router.navigate(['/login'])
            }

            return throwError(() => error);
          })
        );
      }
    }

    return next.handle(request);
  }

  private getAuthorizedRequest(request: HttpRequest<unknown>) {
    return request.clone({
      withCredentials: true,
      setHeaders: {
        Authorization: "Bearer " + this.authService.getLoginToken()
      }
    })
  }
}
