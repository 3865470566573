import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppProperties } from '../config/app.properties';
import { Observable } from "rxjs";
import { Filter } from "../model/filter.model";
import { Activity } from "../model/activity.model";
import { Page } from "../model/page.model";

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(private http: HttpClient, private appProperties: AppProperties) {
  }

  getByFilter(filter: Filter): Observable<Page<Activity>> {
    return this.http.post<Page<Activity>>(this.appProperties.API_URL, filter);
  }
}
