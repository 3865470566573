<div>
  <form (submit)="getByFilter()">
    <mat-form-field appearance="fill">
      <mat-label>Browser Or Device Id</mat-label>
      <input matInput [formControl]="form.controls.browserOrDeviceId">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Session Id</mat-label>
      <input matInput [formControl]="form.controls.sessionId">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>User Id</mat-label>
      <input type="number" matInput [formControl]="form.controls.userId">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Activity Type</mat-label>
      <mat-select [formControl]="form.controls.activityType">
        <mat-option>Any</mat-option>
        <mat-option *ngFor="let activityType of activityTypes" value="{{activityType}}">{{activityType}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Content Id</mat-label>
      <input type="number" matInput [formControl]="form.controls.contentId">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Content Term</mat-label>
      <input matInput [formControl]="form.controls.contentTerm">
    </mat-form-field>
    <mat-form-field appearance="fill">
      <mat-label>Device Type</mat-label>
      <mat-select [formControl]="form.controls.deviceType">
        <mat-option>Any</mat-option>
        <mat-option *ngFor="let deviceType of deviceTypes" value="{{deviceType}}">{{deviceType}}</mat-option>
      </mat-select>
    </mat-form-field>
    <button mat-raised-button color="primary" type="submit">Get Activities</button>
  </form>
  <table mat-table [dataSource]="activityPage.content" class="mat-elevation-z8">
    <ng-container matColumnDef="browserOrDeviceId">
      <th mat-header-cell *matHeaderCellDef> Browser Or Device Id </th>
      <td mat-cell *matCellDef="let element"> {{element.browserOrDeviceId}} </td>
    </ng-container>
    <ng-container matColumnDef="sessionId">
      <th mat-header-cell *matHeaderCellDef> Session Id </th>
      <td mat-cell *matCellDef="let element"> {{element.sessionId}} </td>
    </ng-container>
    <ng-container matColumnDef="userId">
      <th mat-header-cell *matHeaderCellDef> User Id </th>
      <td mat-cell *matCellDef="let element"> {{element.userId}} </td>
    </ng-container>
    <ng-container matColumnDef="activityType">
      <th mat-header-cell *matHeaderCellDef> Activity Type </th>
      <td mat-cell *matCellDef="let element"> {{element.activityMeta.activityType}} </td>
    </ng-container>
    <ng-container matColumnDef="contentId">
      <th mat-header-cell *matHeaderCellDef> Content Id </th>
      <td mat-cell *matCellDef="let element"> {{element.contentId}} </td>
    </ng-container>
    <ng-container matColumnDef="contentTerm">
      <th mat-header-cell *matHeaderCellDef> Content Term </th>
      <td mat-cell *matCellDef="let element"> {{element.contentTerm}} </td>
    </ng-container>
    <ng-container matColumnDef="deviceType">
      <th mat-header-cell *matHeaderCellDef> Device Type </th>
      <td mat-cell *matCellDef="let element"> {{element.deviceType}} </td>
    </ng-container>
    <ng-container matColumnDef="remarks">
      <th mat-header-cell *matHeaderCellDef> Remarks </th>
      <td mat-cell *matCellDef="let element"> {{element.remarks}} </td>
    </ng-container>
    <ng-container matColumnDef="createdAt">
      <th mat-header-cell *matHeaderCellDef> Created At </th>
      <td mat-cell *matCellDef="let element"> {{element.createdAt  | date: 'medium'}} </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-divider></mat-divider>
  <mat-paginator
      class="demo-paginator"
      (page)="handlePageEvent($event)"
      [length]="activityPage.totalElements"
      [pageSize]="activityPage.size"
      [showFirstLastButtons]="true"
      [pageSizeOptions]="[5, 10, 50, 100, 500]"
      aria-label="Select page">
  </mat-paginator>
</div>
