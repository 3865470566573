import { Injectable } from '@angular/core';
import { Login } from "../model/login.model";
import { catchError, map, Observable, of } from "rxjs";
import { DecodedJWT, JWTokens, Response } from "../model/jwttokens.model";
import jwtDecode from "jwt-decode";
import * as moment from "moment";
import { HttpClient } from "@angular/common/http";
import { AppProperties } from "../config/app.properties";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private appProperties: AppProperties, private router: Router) {
  }

  login(login: Login): Observable<Response<JWTokens>> {
    return this.http.post<Response<JWTokens>>(this.appProperties.API_LOGIN_URL, login)
      .pipe(
        map(res => {
          this.setSession(res);
          return res;
        }),
        catchError(err => {
          return of(err.error);
        })
      );
  }

  refreshToken(): Observable<Response<JWTokens>> {
    return this.http.post<Response<JWTokens>>(this.appProperties.API_LOGIN_REFRESH_URL, {refreshToken: this.getRefreshToken()})
      .pipe(
        map(res => {
          this.setSession(res);
          return res;
        })
        ,
        catchError(err => {
          return of(err.error);
        })
      );
  }

  public setSession(response: Response<JWTokens>) {
    let jwtoken = response.data;
    if (!jwtoken) {
      return;
    }

    let decodedJWT = this.decodeJwt(jwtoken.loginToken);
    localStorage.setItem("userEmail", decodedJWT.email);
    localStorage.setItem("userRoles", decodedJWT.roles);
    localStorage.setItem("loginTokenExpiresAt", decodedJWT.exp.toString());
    localStorage.setItem("loginToken", jwtoken.loginToken);

    if (jwtoken.refreshToken) {
      let decodedRefreshJWT = this.decodeJwt(jwtoken.refreshToken);
      localStorage.setItem("refreshToken", jwtoken.refreshToken);
      localStorage.setItem("refreshTokenExpiresAt", decodedRefreshJWT.exp.toString());
    }
  }

  public logout() {
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userRoles");
    localStorage.removeItem("loginTokenExpiresAt");
    localStorage.removeItem("refreshTokenExpiresAt");
    localStorage.removeItem("loginToken");
    localStorage.removeItem("refreshToken");
    this.router.navigate(['/login'])
  }

  public getLoginToken() {
    return localStorage.getItem("loginToken");
  }

  private getRefreshToken() {
    return localStorage.getItem("refreshToken");
  }

  public getUserEmail() {
    return localStorage.getItem("userEmail");
  }

  public getRoles() {
    return localStorage.getItem("userRoles")?.split(',');
  }

  public isLoggedIn() {
    return moment().isBefore(this.getExpiration());
  }

  public isLoggedOut() {
    return !this.isLoggedIn();
  }

  private getExpiration() {
    const expiration = localStorage.getItem("refreshTokenExpiresAt");
    if (expiration) {
      return moment(new Date(parseInt(expiration) * 1000))
    } else {
      return moment(new Date().getTime() - 10000)
    }
  }

  private decodeJwt(jwtToken: string) {
    return jwtDecode<DecodedJWT>(jwtToken);
  }
}
