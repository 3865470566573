export interface Page<T> {
  content: T[];
  totalElements: number;
  totalPages: number;
  size: number;
}

export function getEmptyPage(): Page<any> {
  return {
    content: [],
    totalElements: 0,
    totalPages: 0,
    size: 0
  }
}
