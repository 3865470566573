import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../../../service/auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.router.navigateByUrl("/")
    }
  }

  error?: string
  form = new FormGroup({
    email: new FormControl(),
    password: new FormControl(),
  });

  submit() {
    let username = this.form.controls.email.value
    let password = this.form.controls.password.value
    if (!username && !password) {
      this.error = "Username or password can not be empty!"
      return
    }
    let login = {username: username, password: password}
    this.authService.login(login)
      .subscribe(res => {
        if (res.result) {
          this.error = ""
          this.router.navigateByUrl("/")
        } else {
          this.error = res.message
        }
      })
  }
}
